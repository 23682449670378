.article-wrapper {
  margin-top: 5rem;
  max-width: 750px;
}

.article-avatar {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgb(211, 211, 211);
  background-image: url("/assets/kp-img.jpg");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  margin-right: 25px;
}

.artical-avatar-wrapper {
  display: flex;
}

.artical-img {
  height: 450px;
  width: 100%;
  max-width: 950px;

  background-image: url("/assets/photo-panels-L.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
