.ven-heding {
  margin-top: 5rem;
  margin-bottom: 3rem;
  max-width: 900px;
}

.ven-hre-img {
  max-width: 1200px;
  width: 100%;
  height: 56vw;
  max-height: 500px;
  margin-bottom: 3rem;
}

.ven-text {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > p {
    max-width: 900px;
    padding: 0rem 3rem;
    padding-bottom: 4rem;
    font-size: 1.15rem;
  }

  border-bottom: 1px solid #ececec;
}

.ven-img-wrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  max-width: 900px;
  width: 100%;
  padding: 0rem 3rem;
  margin-bottom: 4rem;
}

.ven-img {
  width: 45%;
  height: intrinsic;
}
