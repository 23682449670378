.viso-hero {
  display: grid;
  height: 600px;
  max-width: 1400px;
  width: 100%;
  grid-template-columns: 50% 50%;
}

.viso-item-1 {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.viso-item-2 {
  background-image: url("/assets/photo-earth-L.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.viso-text {
  width: 100%;
  max-width: 1400px;
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8rem;
  > p {
    max-width: 900px;
    padding: 0rem 3rem;
    > ul li {
      padding-bottom: 15px;
    }
    > a {
      font-family: "Inter", sans-serif !important;
      font-size: 1.15rem;
      color: blue;
      // padding-left: 4px;
    }
  }
}
ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}

@media (max-width: 992px) {
  .viso-hero {
    height: auto;
    grid-template-columns: auto;
    grid-template-rows: 250px 400px;
  }
}
