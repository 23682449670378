.tm-hero--wrapper {
  height: 300px;
  width: 100%;
}

.tm-hero {
  height: 400px;
  padding: 0px 3rem;
  background-color: black;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tm-hero-text {
  max-width: 1400px;
  width: 100%;
  //   padding: 0px 3rem;
}

.tm-sec2 {
  margin-top: 3rem;
  width: 100%;
  padding: 0rem 3rem;
  display: flex;
  justify-content: center;
}

.tm-sec2--inner {
  max-width: 1400px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 3rem;
  border-bottom: 1px solid #ececec;
}
.bio-img-wrapper {
  width: 350px;
  height: 380px;
  min-width: 350px;
  min-height: 380px;
  background-color: grey;
  margin-left: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.bio-img1 {
  background-image: url("/assets/kp-img.jpg");
}
.bio-img2 {
  background-image: url("/assets/ih-img.jpg");
}
.bio-img3 {
  background-image: url("/assets/mp-img.jpg");
}

.tm-bio-wrapper {
  margin-top: 140px;
}
.tm-bio {
  max-width: 700px;
  max-height: 93px;
  overflow: hidden;
  -webkit-transition: max-height 0.3s; /* For Safari 3.1 to 6.0 */
  transition: max-height 0.3s;

  font-size: 0.9rem;
  color: gray;
}

.full {
  -webkit-transition: max-height 0.7s; /* For Safari 3.1 to 6.0 */
  transition: max-height 0.7s;
  max-height: 500px;
  height: auto;
  color: black !important;
}

.rm-btn-2 {
  background-color: #ffffff00 !important;
  cursor: pointer;
  padding: 10px 15px;
  border-width: 0px;
  margin-bottom: 20px;
  margin-bottom: 0px;
  text-align: start;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-size: 0.85rem;

  border: black solid 0.5px;
}
.rm-btn-2:focus {
  outline: blue solid 1px;
  border: blue solid 1px;
}

.img-right {
  background-position: right;
}

@media (max-width: 992px) {
  .tm-sec2--inner {
    flex-direction: column-reverse;
  }
  .bio-img-wrapper {
    width: 100%;
    min-width: auto;
    margin-bottom: 2rem;
    margin-left: 0rem;
  }
  .tm-bio-wrapper {
    margin-top: 0px;
  }
  .rm-btn-2 {
    border: black solid 1px;
  }
}
