.con-hero-text {
  max-width: 1400px;
  width: 100%;
  margin-top: 3rem;
  border-bottom: 1px solid #ececec;
}

.con-grid {
  max-width: 1400px;
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-template-rows: 400px;
  border-bottom: 1px solid #ececec;
}

.com-input {
  width: 100% !important;
  height: 30px;
  border: black solid 1px;
  padding: 20px 15px;
  margin-bottom: 1.1rem;
}
.com-input:focus {
  outline: blue solid 1px;
  border: blue solid 1px;
}
#large-input {
  height: 80px !important;
  width: 100% !important;
  border: black solid 1px;
  padding: 10px 15px;
  margin-bottom: 1.1rem;
}

#large-input:focus {
  outline: blue solid 1px;
  border: blue solid 1px;
}

#snd-msg--btn {
  height: 40px !important;
  border: black solid 1px;
  padding: 10px 25px;
  margin-bottom: 1.5rem;
  cursor: pointer;
  background-color: white;
}
#snd-msg--btn:focus {
  outline: blue solid 1px;
  border: blue solid 1px;
}
.con-item2 {
  padding: 0rem 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 992px) {
  .con-grid {
    grid-template-rows: auto auto;
    grid-template-columns: 100%;
  }
  .con-item2 {
    padding: 0px 0px 3rem 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
