.img-open-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 30, 52, 0.37);
  z-index: 100;
  padding: 5rem 2rem;
  min-height: 800px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.img-open-frame {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  background-color: white;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.img-close-btn {
  width: 50px;
  height: 50px;
  background-color: #f8f8f8;
  position: absolute;
  right: 2rem;
  top: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.img-close-btn:hover {
  background-color: #eeeeee;
}

.img-int-wrapper {
  width: 100%;
  height: 100%;
  padding: 5rem 2rem;
  overflow: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.img-wrapper {
  height: 100%;
  width: 100%;
  max-width: 800px;
  > img {
    width: 100% !important;
    min-width: 100%;
    margin-bottom: 2rem;
  }
}

.img-title {
  text-align: start;
  width: 100%;
  max-width: 800px;
}

/* width */
.img-int-wrapper::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.img-int-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.img-int-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.img-int-wrapper::-webkit-scrollbar-thumb:hover {
  background: blue;
}

.img-btn-wrapper {
  border: 0;
  border-radius: 0.25rem;
  background: none;
  font-family: system-ui, sans-serif;
  font-size: 0rem;
  line-height: 0;
  white-space: nowrap;
  text-decoration: none;
  padding: 0rem 0rem;
  margin: 0rem;
  width: 45%;
  height: intrinsic;
  cursor: pointer;
  > img {
    width: 100%;
  }
}

.ven-img {
  width: 45%;
  height: intrinsic;
}

@media (max-width: 550px) {
  .img-open-wrapper {
    padding: 0rem 0rem;
  }
}
