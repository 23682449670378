.sim-grid {
  height: 400px;
  width: 100%;
  max-width: 1400px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 25% 25% 25% 25%;
}

.sim-item {
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  align-items: flex-start;
  color: black;
  -webkit-transition: background-color 0.2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.2s;

  //button
  background-color: #ffffff00 !important;
  cursor: pointer;
  border-width: 0px;
  padding: 15%;
}

.sim-item:hover {
  background-color: #eeeeee !important;
  > .desc-toggle {
    max-height: 48px;
    height: auto;
  }
}

.desc-toggle {
  position: relative;
  display: flex;
  max-height: 0px;
  -webkit-transition: max-height 0.3s; /* For Safari 3.1 to 6.0 */
  transition: max-height 0.3s;
  overflow: hidden;
}

@media (max-width: 992px) {
  .sim-grid {
    height: auto;
    grid-template-rows: 25% 25% 25% 25%;
    grid-template-columns: auto;
  }
  .sim-item {
    padding: 5% 3rem;
    height: 200px;
    border-bottom: 1px solid #ececec;
  }
}
