.navbar {
  height: 100px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0pc 30px;
  > nav {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      float: left;
      display: block;
      text-align: center;
      margin: 0px 55px 5px 55px;
      text-decoration: none;
      > a {
        font-weight: 500;
      }
    }
  }
}

#logo {
  height: 2.8rem;
}

.navbar-menu {
  position: relative;
  display: flex;
}

#nav--button {
  cursor: pointer;
  position: absolute;
  right: 30px;
  z-index: 100;
  border-width: 0px;
  background: white;
  justify-content: space-around;
  flex-direction: column;
  height: 25px;
  display: none;
  padding: 0px;
}

.burger-line {
  background-color: black;
  width: 22px;
  height: 2px;
}

@media (max-width: 992px) {
  .hidden {
    display: none;
  }
  #nav--button {
    display: flex;
  }
  .navbar {
    padding: 0px 0px;
  }
  #logo {
    padding-left: 30px;
  }
  .navbar-menu {
    position: fixed;
    top: 0px;
    height: 101vh;
    background-color: white;
    z-index: 50;
    // min-height:700px;
    width: 100%;
    align-items: center;
    justify-content: center;
    > ul {
      padding: 0px;
      > li {
        margin: 0px 0px 55px 0px;
        float: none;
        display: block;
        text-align: center;
        > a {
          font-size: 1.9rem;
        }
      }
    }
  }
}
