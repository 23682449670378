.footer {
  padding: 0px 30px;
  height: auto;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    font-family: "Inter", sans-serif !important;
    font-weight: 400;
    color: grey;
    text-decoration: none;
    font-size: 0.7rem;
  }
}

.footer-sec1 {
  width: 100%;
  max-width: 1400px;
  display: flex;
  margin: 40px 0px;
}
.logo-w {
  > svg {
    > path {
      fill: white !important;
    }
    > ellipse {
      fill: white !important;
    }
  }
}

.footer-list {
  > ul li {
    list-style-type: none;
    padding: 5px 0px;
    > a {
      color: grey !important;
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 992px) {
  .footer-sec1 {
    flex-direction: column;
  }
  .footer-list {
    > ul {
      padding-left: 13px !important;
    }
  }
}
