.app {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.blue {
  color: #206d92;
}
.grey {
  color: grey;
}

.mw-1400 {
  max-width: 1400px;
}

.ces-green {
  color: #0c8b8b;
}
a {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  color: black;
  text-decoration: none;
  font-size: 0.8rem;
}

h1 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 2.2rem;
}

h2 {
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 1.3rem;
}

//small text (h3)
h3 {
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 0.8rem;
}
h4 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 1.5rem;
}

p {
  font-family: "Inter", sans-serif !important;
  font-size: 1.1rem;
}
.drop-cap {
  float: left;
  color: #000000;
  font-size: 100px;
  line-height: 70px;
  padding-right: 20px;
}
.fadeIn {
  animation: fadeIn 0.9s ease 0s 1 normal forwards;
  will-change: opacity;
  animation-fill-mode: both;
}

.fadeInAndUp {
  animation: fadeInAndUp 0.9s ease 0s 1 normal forwards;
  will-change: transform, opacity;
  animation-fill-mode: both;
}

.fadeInAndUpQuick {
  animation: fadeInAndUp 0.3s ease 0s 1 normal forwards;
  will-change: transform, opacity;
  animation-fill-mode: both;
}

.menufadeInAndUp {
  animation: fadeInAndUp 0.5s ease 0s 1 normal forwards;
  will-change: transform, opacity;
  animation-fill-mode: both;
}

.nav-link:hover {
  > .underline-wrapper {
    > .underline-bar {
      background-color: black;
      width: 100%;
      height: 100%;

      -webkit-transition: width 0.2s; /* For Safari 3.1 to 6.0 */
      transition: width 0.2s;
    }
  }
}

.underline-wrapper {
  margin-top: 2px;
  width: 100%;
  height: 1.5px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.underline-bar {
  background-color: black;
  width: 0%;
  height: 100%;

  -webkit-transition: width 0.2s; /* For Safari 3.1 to 6.0 */
  transition: width 0.2s;
}

.is-active {
  > .underline-wrapper .underline-bar {
    width: 100% !important;
  }
}

.divider {
  margin-top: 3rem;
  width: 100%;
  height: 1px;
  background-color: #ececec;
  max-width: 1400px;
}

@keyframes menufadeInAndUp {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInAndUp {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

@keyframes fadeInAndUpQuick {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

@import "~bootstrap-4-grid/scss/grid.scss";

@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
