.lan-page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  // padding: 0rem 2rem;
  justify-content: center;
}

.lan-grid {
  max-width: 1400px;
  display: grid;
  width: 100%;
  grid-template-columns: calc(50% - 0.5vw) calc(50% - 0.5vw);
  grid-template-rows: 600px 250px;
  grid-gap: 1vw 1vw;
  margin-bottom: 1vw;
}

.lan-item-1 {
  grid-row: 1 / span 1;
  grid-column: 1 / span 2;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: black;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  display: grid;
  width: 100%;
  height: 100%;
}
.lan-inn-item-1 {
  background-color: #f4f4f4;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.lan-inn-item-2 {
  background-image: url("/assets/photo-wind-L.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 3rem;
}

.lan-item-2-inner--wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-transition: background-color 0.3s ease; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.3s ease;
  > .lan-item-3-inner-1 {
    max-height: 90px;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease; /* For Safari 3.1 to 6.0 */
    transition: max-height 0.3s ease;
  }
}
.lan-item-2-inner--wrapper:hover {
  background-color: blue;
  -webkit-transition: background-color 0.3s ease; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.3s ease;

  > .lan-item-3-inner-1 {
    max-height: 131px;
    height: auto;
    overflow: hidden;

    -webkit-transition: max-height 0.3s ease; /* For Safari 3.1 to 6.0 */
    transition: max-height 0.3s ease;
  }
}

.lan-item-2 {
  background-image: url("/assets/photo-earth-L.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lan-item-3 {
  background-image: url("/assets/photo-panels-L.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lan-item-3-inner-1 {
  padding: 0rem 3rem;
  max-width: 490px;
}

.div--mw {
  max-width: 588px;
}
#btn-m {
  margin-top: 0.05rem;

  -webkit-transition: transform 0.2s ease; /* For Safari 3.1 to 6.0 */
  transition: transform 0.2s ease;
}

.rm-btn {
  max-width: 95px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > a {
    font-weight: 500;
    font-size: 0.85rem;
    max-width: 200px;

    display: flex;
    justify-content: center;
  }
}

.rm-btn:hover {
  #btn-m {
    transform: translateX(5px);

    -webkit-transition: transform 0.2s ease; /* For Safari 3.1 to 6.0 */
    transition: transform 0.2s ease;
  }
}

@media (max-width: 992px) {
  .lan-grid {
    max-width: 1400px;
    display: grid;
    width: 100%;
    grid-template-columns: auto;
    grid-template-rows: 800px 250px 250px;
    grid-gap: 0rem 0rem;
    margin-bottom: 0rem;
  }

  .lan-item-1 {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    display: grid;
    width: 100%;
    height: 100%;
  }
  .lan-inn-item-1 {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    background-color: white;
    color: black !important;
  }
  h1 {
    font-size: 1.6rem !important;
  }
  p {
    font-size: 1rem !important;
  }

  .lan-page {
    padding: 0rem 0rem;
  }

  .lan-item-2-inner--wrapper {
    > .lan-item-3-inner-1 {
      max-height: 170px !important;
      height: auto;
    }
  }
}

@media (max-width: 992px) {
}
